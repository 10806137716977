<template>
  <BaseDialog class="serial-reward-edit-modal" @confirm="onConfirm" @close="$emit('close')">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="獎勵種類" prop="type">
        <el-select v-model="formData.type" placeholder="請選擇獎勵種類">
          <el-option v-for="type in displayRewardTypeConfig" :key="type.value" :label="type.label" :value="type.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="獎勵內容" prop="amount">
        <div class="flex" style="gap: 20px">
          <p>每筆序號可獎勵</p>
          <el-input v-model="formData.amount" :disabled="!formData.type" style="width: 100px" />
          <p v-if="formData.type === 'POINT'">點點數</p>
          <p v-if="formData.type === 'CASHBACK'">元回饋金</p>
        </div>
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { CreateSerialActivityReward, UpdateSerialActivityReward } from '@/api/serialActivity'
import { serialRewardTypeConfig } from '@/config/serialActivity'
import { useShop } from '@/use/shop'
import { useRoute } from 'vue-router/composables'
import { get, omit } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SerialRewardEditModal',
  components: { BaseDialog },
  props: {
    rewardData: { type: Object, default: () => ({}) },
  },
  emits: ['close', 'rewardUpdate'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const route = useRoute()
    const { checkAction } = usePermissions()
    const { initFormData, formRef, formData, onSubmit, loading } = useBaseForm()
    initFormData({
      type: null,
      amount: null,
    })
    const formRules = computed(() => {
      const rules = {
        type: [noEmptyRules()],
        amount: [noEmptyRules()],
      }

      // handle rules here

      return rules
    })
    const activityId = computed(() => route.params.id)
    const displayRewardTypeConfig = computed(() => {
      const omitList = []
      const useShopPoint = checkAction('admin.shopPoint.page')
      const useCashback = checkAction('admin.salesCashback.page')
      if (!useShopPoint) omitList.push('POINT')
      if (!useCashback) omitList.push('CASHBACK')

      return omit(serialRewardTypeConfig, omitList)
    })

    const createSerialActivityReward = async (data) => {
      const [, err] = await CreateSerialActivityReward({
        shopId: shopId.value,
        serialActivityId: activityId.value,
        rewardType: data.type,
        rewardAmount: Number(data.amount),
      })
      if (err) throw err
      window.$message.success('新增成功！')
    }
    const updateSerialActivityReward = async (data) => {
      const [, err] = await UpdateSerialActivityReward({
        shopId: shopId.value,
        id: get(props.rewardData, 'id'),
        rewardType: data.type,
        rewardAmount: Number(data.amount),
      })
      if (err) throw err
      window.$message.success('新增成功！')
    }
    const handleSubmit = async (data, { emit, loading }) => {
      try {
        const isEdit = Boolean(get(props.rewardData, 'id'))
        if (isEdit) await updateSerialActivityReward(data)
        if (!isEdit) await createSerialActivityReward(data)
        emit('rewardUpdate')
        emit('close')
      } catch (error) {
        loading.value = false
        window.$message.error(error)
      }
    }
    const onConfirm = async () => {
      onSubmit(handleSubmit, formData, { loading, emit })
    }

    const syncData = () => {
      const data = props.rewardData
      formData.type = data.rewardType
      formData.amount = data.rewardAmount
    }

    onMounted(() => {
      if (props.rewardData) syncData()
    })

    return { formRef, formData, formRules, onConfirm, loading, displayRewardTypeConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
