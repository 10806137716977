<template>
  <div class="card-container form-wrapper">
    <div class="flex justify-between items-center">
      <p class="text-primary-100 font-bold text-lg">獎勵內容</p>
      <el-button class="text-danger" type="text" @click="$emit('edit')">編輯</el-button>
    </div>
    <el-form label-position="left" label-width="200px">
      <el-form-item label="獎勵種類" prop="type">
        {{ get(serialRewardTypeConfig, `${get(rewardData, 'rewardType')}.label`) }}
      </el-form-item>
      <el-form-item label="獎勵內容" prop="amount">
        {{ get(rewardData, 'rewardAmount') }}
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { serialRewardTypeConfig } from '@/config/serialActivity'
import { get } from 'lodash'

export default defineComponent({
  name: 'SerialComposFormBlock',
  props: {
    formId: String,
    rewardData: { type: Object, default: () => ({}) },
  },
  emits: ['remove', 'edit'],
  setup (props, { emit }) {
    return {
      get,
      serialRewardTypeConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
.form-wrapper {
  @apply px-[24px] py-[20px];
}
</style>
