<template>
  <div v-loading="loading">
    <PageTitle
      title="活動序號管理"
      icon="chevron_left"
      btn="匯出"
      style="margin-bottom: 64px"
      @btnClick="modal.export = true"
      @iconClick="$router.push({ name: 'RewardActivityList' })"
    />

    <SerialActivityInfoBlock :activity="activityData" />
    <div class="flex justify-center" style="margin-top: 20px">
      <el-button type="text" class="text-primary-100 underline" @click="modal.rewardEdit = true">
        ＋新增獎勵內容與對應序號組合
      </el-button>
    </div>
    <div>
      <div v-for="(rewardId, index) in formBlocks" :key="rewardId" class="flex flex-col" style="gap: 20px">
        <div class="flex justify-between items-center" style="margin-top: 20px">
          <p class="text-primary-100 font-medium">獎勵內容與對應序號組合 {{ index+1 }}</p>
          <el-button class="underline" style="color: var(--danger)" type="text" @click="deleteReward = rewardId, modal.delete = true">
            移除組合
          </el-button>
        </div>
        <SerialComposFormBlock
          :formId="rewardId"
          :rewardData="find(rewardData, {id: rewardId})"
          @edit="onRewardEdit(rewardId)"
        />
        <SerialCodeFormBlock
          :rewardData="find(rewardData, {id: rewardId})"
          :rewardId="rewardId"
        />
      </div>
    </div>

    <SerialRewardEditModal
      v-if="modal.rewardEdit"
      :rewardData="selectReward"
      @close="modal.rewardEdit = false"
      @rewardUpdate="refresh"
    />
    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="removeFormBlock"
    />
    <!-- <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" /> -->

    <ExportOptionsDialog
      v-if="modal.export"
      allRange
      @close="modal.export = false"
      @export="modal.export = false, prepareExport()"
    />
    <ExportDialog
      v-if="modal.exporting"
      :inProgress="false"
      :isError="false"
      :percentage="100"
      :data="[]"
      :total="0"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref, onMounted, reactive } from 'vue'
import SerialRewardEditModal from './components/SerialRewardEditModal.vue'
import SerialActivityInfoBlock from '@/views/RewardActivity/components/SerialActivityInfoBlock.vue'
import SerialComposFormBlock from './components/SerialComposFormBlock.vue'
import SerialCodeFormBlock from './components/SerialCodeFormBlock.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import FormsContext from './formsContext'
import {
  FindSerialActivity, GetSerialActivityReward, DeleteSerialActivityReward,
  GetSerialActivityCode, GetSerialActivityCodeCount,
} from '@/api/serialActivity'
import { useRoute, useRouter } from 'vue-router/composables'
import { useShop } from '@/use/shop'
import { nanoid } from 'nanoid'
import { filter, find, map, get } from 'lodash'
import { ExportMoreSheetExcel } from '@/utils/excel'
import { getAllDataFromApi } from '@/utils/helper'
import { serialRewardTypeConfig, serialActivityCodeOriginConfig } from '@/config/serialActivity'

export default defineComponent({
  name: 'SerialCodeManagement',
  components: {
    SerialActivityInfoBlock,
    // PageFixedFooter,
    SerialComposFormBlock,
    SerialRewardEditModal,
    SerialCodeFormBlock,
    ExportOptionsDialog,
    ExportDialog,
  },
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const { shopId } = useShop()
    const activityData = ref(null)
    const rewardData = ref([])
    const activityId = computed(() => route.params.id)
    const context = ref({})
    const formBlocks = ref([])
    const selectReward = ref(null)
    const deleteReward = ref(null)
    const modal = reactive({
      rewardEdit: false,
      delete: false,
      export: false,
      exporting: false,
    })
    const loading = ref(false)

    const getContext = () => {
      context.value = {
        refs: Object.keys(FormsContext.formRefs),
        data: Object.keys(FormsContext.formData),
      }
    }

    const findSerialActivity = async () => {
      const [res, err] = await FindSerialActivity({
        shopId: shopId.value,
        id: activityId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      activityData.value = res
    }
    const getSerialActivityReward = async () => {
      const [res, err] = await GetSerialActivityReward({
        shopId: shopId.value,
        SerialActivityId: activityId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      res.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      rewardData.value = res
    }

    const syncRewardData = () => {
      if (!rewardData.value.length) return
      formBlocks.value = []
      rewardData.value.forEach(reward => {
        formBlocks.value.push(reward.id)
      })
    }
    const addFormBlock = () => {
      const formId = nanoid(10)
      formBlocks.value.push(formId)
    }

    const removeFormBlock = async () => {
      const rewardId = deleteReward.value
      loading.value = true
      delete FormsContext.formRefs[rewardId]
      delete FormsContext.formData[rewardId]
      formBlocks.value = filter(formBlocks.value, f => f !== rewardId)

      const [, err] = await DeleteSerialActivityReward({
        shopId: shopId.value,
        id: rewardId,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
      }
      modal.delete = false
    }

    const onRewardEdit = (rewardId) => {
      selectReward.value = find(rewardData.value, { id: rewardId })
      modal.rewardEdit = true
    }

    const onCancel = () => {
      router.push({ name: 'SerialCodeManagement' })
    }

    const onSubmit = async () => {
      // const allPass = await FormsContext.validateAll()
      // if (!allPass) return
      router.push({ name: 'SerialCodeManagement' })
    }

    const refresh = async () => {
      loading.value = true
      await Promise.allSettled([
        findSerialActivity(),
        getSerialActivityReward(),
      ])
      syncRewardData()
      loading.value = false
    }

    const fetchRewardAllData = async (rewardId) => {
      const [res, err] = await GetSerialActivityCodeCount({
        shopId: shopId.value,
        SerialActivityId: activityData.value.id,
        SerialActivityRewardId: rewardId,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      const totalDataCount = res.count

      const payload = {
        shopId: shopId.value,
        SerialActivityId: activityData.value.id,
        SerialActivityRewardId: rewardId,
        start: 0,
        limit: 100,
      }

      return await getAllDataFromApi(
        totalDataCount,
        GetSerialActivityCode,
        payload,
        true,
      )
    }

    const getExportData = async () => {
      const rewardIds = map(rewardData.value, 'id')
      const sheetsData = []
      let count = 0
      for (const rewardId of rewardIds) {
        count += 1
        const rewardSetData = find(rewardData.value, { id: rewardId })
        console.log(rewardSetData)
        const sheetData = {
          sheetName: `獎勵組合 ${count}`,
          data: [],
        }

        const [res, err] = await fetchRewardAllData(rewardId)
        res.forEach((item, index) => {
          const type = get(rewardSetData, 'rewardType')
          sheetData.data.push({
            序號: item.code,
            來源: get(serialActivityCodeOriginConfig, `${item.createMethod}.label`),
            獎勵種類: get(serialRewardTypeConfig, `${type}.label`),
            獎勵內容: get(rewardSetData, 'rewardAmount'),
            建立時間: item.createdAt,
            變更時間: item.updatedAt,
          })
        })

        sheetsData.push(sheetData)
      }

      return sheetsData
    }

    const resetExport = () => {
      modal.exporting = false
    }

    const prepareExport = async () => {
      // this.resetExport()

      // if (!this.exportTotal) {
      //   this.$message.warning('沒有資料可以匯出')
      //   return
      // }

      modal.exporting = true
      // this.exportting = true

      const data = await getExportData()
      await ExportMoreSheetExcel(data, '序號管理')
      // exportting = false
    }

    onMounted(async () => {
      refresh()
    })

    return {
      find,
      formBlocks,
      activityData,
      addFormBlock,
      onCancel,
      onSubmit,
      getContext,
      context,
      removeFormBlock,
      selectReward,
      modal,
      onRewardEdit,
      rewardData,
      refresh,
      loading,
      deleteReward,
      resetExport,
      prepareExport,
    }
  },
})
</script>
