import { render, staticRenderFns } from "./SerialCodeFormBlock.vue?vue&type=template&id=8ec8f910&scoped=true"
import script from "./SerialCodeFormBlock.vue?vue&type=script&lang=js"
export * from "./SerialCodeFormBlock.vue?vue&type=script&lang=js"
import style0 from "./SerialCodeFormBlock.vue?vue&type=style&index=0&id=8ec8f910&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ec8f910",
  null
  
)

export default component.exports