<template>
  <BaseDialog
    v-bind="$attrs"
    :title="dialogTitle"
    :btn1Disabled="!formData.code"
    :btn1Loading="loading"
    @confirm="onConfirm"
    @close="$emit('close')"
  >
    <el-form ref="formRef" :model="formData" :rules="formRules">
      <el-form-item :label="serialCodeName" prop="code" :error="codeError">
        <el-input
          v-model="formData.code"
          :placeholder="`請輸入${serialCodeName}`"
        />
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules, lengthRules } from '@/validation'

export default defineComponent({
  name: 'SerialCodeInputModal',
  components: { BaseDialog },
  props: {
    code: { type: String, default: '' },
    mode: { type: String, default: 'create' },
    serialCodeName: { type: String, default: '序號' },
    max: { type: [Number, String], default: 18 },
    min: { type: [Number, String], default: 1 },
    rules: { type: Array, default: () => [] },
    handleSubmit: Function,
  },
  emits: ['close', 'refresh'],
  setup (props, { emit }) {
    const codeError = ref('')
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    const dialogTitle = computed(() => {
      if (props.mode === 'edit') return `編輯${props.serialCodeName}`
      return `新增${props.serialCodeName}`
    })
    initFormData({
      code: null,
    })
    const formRules = computed(() => {
      if (props.rules.length) return { code: props.rules }
      return {
        code: [
          noEmptyRules(),
          lengthRules(Number(props.min), Number(props.max)),
        ],
      }
    })

    const onConfirm = async () => {
      await onSubmit(props.handleSubmit, formData, { emit, codeError, loading })
    }

    onMounted(() => {
      if (props.mode === 'edit') formData.code = props.code
    })
    return {
      dialogTitle,
      formData,
      formRef,
      formRules,
      onConfirm,
      loading,
      codeError,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
